<template>
  <b-overlay :show="loading">
    <div
      class="d-flex flex-column justify-content-center align-items-center text-center"
      style="height: 100vh"
    >
      <h3 class="text-primary">{{ $t("teams.no_team_joined") }}</h3>
      <p class="text-muted">
        {{ $t("teams.click_to_find_team") }}
      </p>
      <b-button variant="primary" to="/teams">
        {{ $t("teams.join_a_team") }}
      </b-button>
    </div>
  </b-overlay>
</template>

<script>
import axios from "axios"

export default {
  data() {
    return {
      TeamId: null,

      loading: false,
    }
  },

  async created() {
    this.loading = true
    try {
      const response = await axios.get("/team?group_id=my")
      let allTeams = response.data?.data ?? []
      let myTeam = allTeams.find(team => {
        return team.my_team === "Y"
      })
      this.TeamId = myTeam.id
    } catch {
      this.TeamId = null
    } finally {
      this.loading = false
    }

    if (this.TeamId) {
      this.$router.replace(`/team/${this.TeamId}`)
    }
  },
}
</script>
